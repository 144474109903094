import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "videoid",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "videoid",
      fn: function fn(videoid) {
        return [_c("span", {
          staticStyle: {
            color: "#4da5ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.showModal(videoid);
            }
          }
        }, [_vm._v(_vm._s(videoid))])];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatField("type", type)))])];
      }
    }, {
      key: "topics",
      fn: function fn(topics) {
        return _vm._l(topics, function (item, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              display: "inline-block"
            }
          }, [item.type != 1 ? _c("span", {
            staticStyle: {
              "margin-left": "5px",
              color: "#dc8636"
            }
          }, [_vm._v("[" + _vm._s(_vm.formatField("type2", item.type)) + "]")]) : _vm._e(), _c("span", [_vm._v("#" + _vm._s(item.name))]), index != topics.length - 1 ? _c("span", [_vm._v(",")]) : _vm._e(), _vm._v("    ")]);
        });
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _vm.visible ? _c("Detail", {
    ref: "detail",
    attrs: {
      visible: _vm.visible,
      loading: _vm.loading,
      editId: _vm.editId,
      showNext: false
    },
    on: {
      previewLog: _vm.previewLog,
      cancel: function cancel($event) {
        _vm.visible = false;
      },
      submit: _vm.handleFormSubmit
    }
  }) : _vm._e(), _vm.visible2 ? _c("DPopup", {
    attrs: {
      title: "\u67E5\u770B\u65E5\u5FD7\u3010".concat(_vm.editId, "\u3011"),
      comVisible: _vm.visible2,
      isClosable: true,
      "z-index": 1001,
      modalWidth: "1000px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.visible2 = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Log", {
          attrs: {
            editId: _vm.editId,
            action: _vm.action
          }
        })];
      },
      proxy: true
    }], null, false, 1136794272)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };